import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const CompanyHeader = () => {
  // Refs for each card and header
  const headerRef = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);

  // Check if each element is in view
  const isHeaderInView = useInView(headerRef, { threshold: 0.1 });
  const isInView1 = useInView(ref1, { threshold: 0.1 });
  const isInView2 = useInView(ref2, { threshold: 0.1 });
  const isInView3 = useInView(ref3, { threshold: 0.1 });
  const isInView4 = useInView(ref4, { threshold: 0.1 });
  const isInView5 = useInView(ref5, { threshold: 0.1 });
  const isInView6 = useInView(ref6, { threshold: 0.1 });
  const isInView7 = useInView(ref7, { threshold: 0.1 });
  const isInView8 = useInView(ref8, { threshold: 0.1 });

  // Animation variants for the cards and header
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  const headerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.7 } },
  };

  return (
    <div className="bg-white px-4 text-center py-32">
      {/* SEO Metadata */}
      <Helmet>
        <title>إنجازات</title>
        <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
      </Helmet>
      {/* Animated Header */}
      <motion.h1
        ref={headerRef}
        className="text-3xl font-bold mb-6 text-black-600" // Updated text color
        initial="hidden"
        animate={isHeaderInView ? 'visible' : 'hidden'}
        variants={headerVariants}
      >
        إنجازات   <span className="text-emerald-600">محاسب سند الذكى</span>
      </motion.h1>

      {/* Grid of Cards with RTL Layout */}
      <div
        className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4  mx-auto justify-items-center px-4 sm:px-6 md:px-16 "
        style={{
          direction: 'rtl',
        }}
      >
        {/* Card 1 */}
        <motion.div
          ref={ref1}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center" // Updated background color
          initial="hidden"
          animate={isInView1 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/trademark1.png" alt="علامه تجاريه" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold mt-2 text-lg">علامه تجاريه</h2>
          <p className="text-emerald-600 text-lg font-bold">+50</p> {/* Updated text color */}
        </motion.div>

        {/* Card 2 */}
        <motion.div
          ref={ref2}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView2 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/hand.png" alt="فرع تم تشغيله" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold mt-2 text-lg">فرع تم خدمته</h2>
          <p className="text-emerald-600 text-lg font-bold">+70</p>
        </motion.div>

        {/* Card 3 */}
        {/* <motion.div
          ref={ref3}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView3 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/3.svg" alt="مدراء تشغيل" className="h-10 w-10 mb-2" />
          <h2 className="font-semibold mt-2 text-lg">مدراء تشغيل</h2>
          <p className="text-emerald-600 text-lg font-bold">+50</p>
        </motion.div> */}

        {/* Card 4 */}
        {/* <motion.div
          ref={ref4}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView4 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/4.svg" alt="عامل تم تدريبه" className="h-10 w-10 mb-2" />
          <h2 className="font-semibold mt-2 text-lg">عامل تم تدريبه</h2>
          <p className="text-emerald-600 text-lg font-bold">+10000</p>
        </motion.div> */}

        {/* Card 5 */}
        <motion.div
          ref={ref5}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView5 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/sales.png" alt="زياده المبيعات" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold mt-2 text-lg">زياده المبيعات</h2>
          <p className="text-emerald-600 text-lg font-bold">%20</p>
        </motion.div>

        {/* Card 6 */}
        <motion.div
          ref={ref6}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView6 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/money-bag.png" alt="خفض المصروفات" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold mt-2 text-lg">خفض المصروفات</h2>
          <p className="text-emerald-600 text-lg font-bold">%70</p>
        </motion.div>

        {/* Card 7 */}
        <motion.div
          ref={ref7}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView7 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/financial-profit.png" alt="نمو الأرباح" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold text-lg">نمو الأرباح</h2>
          <p className="text-emerald-600 text-lg font-bold">%20</p>
        </motion.div>

        {/* Card 8 */}
        <motion.div
          ref={ref8}
          className="bg-slate-100 p-4 rounded-xl text-center w-full h-[180px] md:h-[150px] m-1 flex flex-col items-center justify-center"
          initial="hidden"
          animate={isInView8 ? 'visible' : 'hidden'}
          variants={cardVariants}
        >
          <img src="/money-management.png" alt="خبره في التشغيل" className="h-10 w-10 mb-1" />
          <h2 className="font-semibold mt-2 text-lg">مصروفات تمت إدارتها  </h2>
          <p className="text-emerald-600 text-lg font-bold">+7M</p>
        </motion.div>
      </div>
    </div>
  );
};

export default CompanyHeader;
