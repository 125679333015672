import { Helmet } from "react-helmet-async";
const Jobs = () => {

  return (
    <div>
      {/* New Section */}
      <div className="text-center pt-32 mb-12">
        {/* Helmet for SEO */}
        <Helmet>
          <title>الوظائف</title>
          <meta name="description" content="Explore our esteemed clients who trust us with their financial technology needs." />
          <meta name="keywords" content="Clients, Financial Technology, سند الذكي" />
        </Helmet>
        <h2 dir="rtl" className="text-4xl md:text-5xl font-bold mb-4 p-2">
          فريقنا هو الأكثر موهبة في مجال التقنيه الماليه.
        </h2>
        <button className="bg-gray-800 text-white py-2 px-6 rounded-full hover:bg-gray-700 transition"
          onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
        >
          انضم إلينا
        </button>
      </div>

      {/* Previous Jobs Section */}
      <div className="bg-blue-50 p-8 pb-10 rounded-3xl">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Job 1 */}
          <div
            dir="rtl"
            className="flex flex-col items-center text-center space-y-4 order-2 md:order-1"
          >
            <div className="text-3xl">✔️</div>
            <h3 className="text-xl font-bold">موظفين مهرة</h3>
            <p>اعمل مع عقول متخصصة وبارعة من جميع الخلفيات.</p>
          </div>

          {/* Job 2 */}
          <div
            dir="rtl"
            className="flex flex-col items-center text-center space-y-4 order-1 md:order-2"
          >
            <div className="text-3xl">💻</div>
            <h3 className="text-xl font-bold">العمل عن بعد</h3>
            <p>
              أنت تعرف أين تكون أكثر إنتاجية، العمل من مقرنا الرئيسي في الرياض أو في
              أي مكان آخر في العالم.
            </p>
          </div>

          {/* Job 3 */}
          <div
            dir="rtl"
            className="flex flex-col items-center text-center space-y-4 order-4 md:order-3"
          >
            <div className="text-3xl">⏰</div>
            <h3 className="text-xl font-bold">ساعات مرنة</h3>
            <p>
              نحن نوظف أشخاصاً قادرين على القيام بعمل رائع بشروطهم الخاصة مع جداول
              تناسب أسواق الإنتاجية والتزامات أوسع.
            </p>
          </div>

          {/* Job 4 */}
          <div
            dir="rtl"
            className="flex flex-col items-center text-center space-y-4 order-3 md:order-4"
          >
            <div className="text-3xl">📈</div>
            <h3 className="text-xl font-bold">إعتمادك لدينا</h3>
            <p>
              بعد اجتيازك لاختباراتنا بنجاح، ستكون على قائمة المحاسبين المعتمدين
              وسنقوم بتوصيلك بأحد عملائنا عند الحاجة.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Jobs;
