import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
const FAQ = () => {
  // Define your list of questions and answers
  const faqData = [
    {
      question: 'ما هو المحاسب الذكي؟',
      answer: '.المحاسب الذكي هو نظام محاسبي يعتمد على الذكاء الاصطناعي لأتمتة المهام المحاسبية وتبسيط العمليات للشركات الصغيرة والمتوسطة, بحيث يغنيك عن محاسب بشري' },

    {
      question: 'كيف يمكنني إرسال الفواتير إلى النظام؟',
      answer: 'يمكنك إرسال الفواتير عبر التحميل المباشر، البريد الإلكتروني، أو حتى عبر تطبيق WhatsApp.'
    },
    {
      question: 'هل يمكن للمحاسب الذكي أن يحل محل المحاسب البشري؟',
      answer: 'نعم، المحاسب الذكي مصمم لتولي المهام المحاسبية الأساسية، مما يلغي الحاجة إلى توظيف محاسب محترف ويقلل من التكاليف.'
    },
    {
      question: 'كيف يساعد المحاسب الذكي في توفير الوقت؟',
      answer: 'من خلال أتمتة عمليات الإدخال والتصنيف وأرشفة الفواتير، يوفر المحاسب الذكي الوقت الذي كان يضيع في العمليات اليدوية.'
    },
    {
      question: 'هل المحاسب الذكي آمن للاستخدام؟',
      answer: 'نعم، المحاسب الذكي يستخدم تقنيات حديثة لضمان أمان البيانات والكشف عن الاحتيالات المالية بشكل أسرع وأكثر دقة.'
    },
    {
      question: 'هل يمكن للمحاسب الذكي مساعدتي في اتخاذ القرارات المالية؟',
      answer: 'بالطبع، المحاسب الذكي يوفر تقارير مالية دقيقة تساعدك في اتخاذ قرارات مالية مستنيرة وتحليل البيانات بشكل أفضل.'
    },
    {
      question: 'ما هي القطاعات التي تحتاج إلى المحاسب الذكي؟',
      answer: 'المحاسب الذكي مناسب للعديد من القطاعات، مثل المكاتب الخدميه ومحلات التجارة بالتجزئة، تقديم الخدمات، التصنيع، الشركات الناشئة، والشركات الصغيرة والمتوسطة التي تبحث عن أتمتة عملياتها المحاسبية وتوفير التكاليف والوقت.'
    },
   
    
    // Add more FAQs as needed
  ];

  // State to track which answer is currently visible
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle the visibility of an answer
  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full py-28 bg-white" dir="rtl">
         {/* Helmet for SEO */}
         <Helmet>
        <title>المقالات</title>
        <meta name="description" content="Explore our esteemed clients who trust us with their financial technology needs." />
        <meta name="keywords" content="Clients, Financial Technology, سند الذكي" />
      </Helmet>

      {/* Heading */}
      <h2 className="text-3xl text-center text-emerald-600 font-bold mb-4">الأسئلة الشائعة</h2>

      {/* FAQ List */}
      <div className="max-w-4xl mx-auto p-3 flex flex-col sm:flex-row gap-4">
        {/* Left Column */}
        <div className="flex-1 space-y-2">
          {faqData
            .filter((_, index) => index % 2 === 0) // FAQs for the left column (even index)
            .map((item, index) => (
              <div key={index} className="flex flex-col bg-gray-100 rounded-lg">
                <button
                  onClick={() => toggleAnswer(index * 2)}
                  className="w-full text-right text-lg font-bold text-gray-800 p-3 bg-gray-100 rounded-lg focus:outline-none"
                >
                  {item.question}
                </button>
                {/* Answer */}
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index * 2 ? 'max-h-screen p-3' : 'max-h-0 p-0'
                  }`}
                >
                  <div className="text-right text-gray-600 bg-gray-50 rounded-lg">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {/* Right Column */}
        <div className="flex-1 space-y-2">
          {faqData
            .filter((_, index) => index % 2 === 1) // FAQs for the right column (odd index)
            .map((item, index) => (
              <div key={index} className="flex flex-col bg-gray-100 rounded-lg">
                <button
                  onClick={() => toggleAnswer(index * 2 + 1)}
                  className="w-full text-right text-lg font-bold text-gray-800 p-3 bg-gray-100 rounded-lg focus:outline-none"
                >
                  {item.question}
                </button>
                {/* Answer */}
                <div
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    activeIndex === index * 2 + 1 ? 'max-h-screen p-3' : 'max-h-0 p-0'
                  }`}
                >
                  <div className="text-right text-gray-600 bg-gray-50 rounded-lg">
                    {item.answer}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
