import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

const FeaturesSection = () => {
  // Refs for right-side elements
  const refTitle = useRef(null);
  const refText1 = useRef(null);
  const refText2 = useRef(null);
  const refButton = useRef(null);

  // Use in-view hooks for animations
  const isInViewTitle = useInView(refTitle, { threshold: 0.5 });
  const isInViewText1 = useInView(refText1, { threshold: 0.5 });
  const isInViewText2 = useInView(refText2, { threshold: 0.5 });
  const isInViewButton = useInView(refButton, { threshold: 0.5 });

  return (
    <div className="container py-32 max-w-full" dir="rtl">
      {/* SEO Metadata */}
      <Helmet>
        <title>لماذا نحن</title>
        <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
      </Helmet>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-8">
        {/* Right Side - Text Section */}
        <div className="flex flex-col justify-center items-start overflow-x-hidden text-right md:w-full">
          {/* Title */}
          <motion.h2
            ref={refTitle}
            initial={{ opacity: 0, x: 100 }} // Start from the right
            animate={{ opacity: isInViewTitle ? 1 : 0, x: isInViewTitle ? 0 : 100 }} // Animate to visible
            transition={{ duration: 1.2 }} // Increased animation duration for title
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            لماذا<span className="text-emerald-600"> محاسب سند الذكي</span>؟
          </motion.h2>

          {/* Paragraph 1 */}
          <motion.p
            ref={refText1}
            initial={{ opacity: 0, y: 50 }} // Start from the bottom
            animate={{ opacity: isInViewText1 ? 1 : 0, y: isInViewText1 ? 0 : 50 }} // Animate to visible
            transition={{ duration: 0.8 }} // Increased animation duration for first paragraph
            className="text-lg text-gray-600 mb-6 font-bold"
          >
            مع محاسب سند الذكي، لن تحتاج إلى فريق محاسبي داخل منشأتك. نقدم لك حلاً متكاملاً يعتمد على الذكاء الاصطناعي، يغنيك عن توظيف محاسبين ويقلل من تكاليف الإدارة التقليدية. </motion.p>

          {/* Paragraph 2 */}
          <motion.p
            ref={refText2}
            initial={{ opacity: 0, y: 50 }} // Start from the bottom
            animate={{ opacity: isInViewText2 ? 1 : 0, y: isInViewText2 ? 0 : 50 }} // Animate to visible
            transition={{ duration: 1.0 }} // Increased animation duration for second paragraph
            className="text-lg text-gray-600 mb-12 font-bold"
          >
            نقوم بأتمتة العمليات المحاسبية بالكامل، بدءًا من قراءة الفواتير وتصنيف البنود المحاسبية وصولاً إلى إدخال البيانات بدقة في النظام، وكل ذلك دون تدخل بشري ونقوم بربطك بمحاسب محترف اذا اردت لمتابعة سير عملك .          </motion.p>

          {/* Button */}
          <motion.button
            ref={refButton}
            initial={{ opacity: 0, x: -100, scale: 0 }} // Start from the bottom
            animate={{ opacity: isInViewButton ? 1 : 0, x: isInViewButton ? 0 : -100, scale: isInViewButton ? 1 : 0 }} // 
            transition={{ duration: 0.6 }} // Increased animation duration for button
            onClick={() => window.open('https://wa.me/966545818756', '_blank', 'noopener noreferrer')}
            className="bg-emerald-600 text-white py-3 px-6 rounded-lg hover:bg-emerald-500 hover:text-black transition duration-300"
          >
            تواصل معنا
          </motion.button>
        </div>

        {/* Left Side - Feature Cards Section */}
        <div className="grid grid-cols-1  sm:grid-cols-2 gap-4 md:w-full py-8 ml-0 md:ml-6 max-w-full">
          {/* Card 1 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0 }} // Increased animation duration for Card 1
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start">
              <div className="flex items-center justify-center bg-emerald-600 rounded-lg p-1 m-1 w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFD700">
                  <path d="m105-233-65-47 200-320 120 140 160-260 109 163q-23 1-43.5 5.5T545-539l-22-33-152 247-121-141-145 233ZM863-40 738-165q-20 14-44.5 21t-50.5 7q-75 0-127.5-52.5T463-317q0-75 52.5-127.5T643-497q75 0 127.5 52.5T823-317q0 26-7 50.5T795-221L920-97l-57 57ZM643-217q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm89-320q-19-8-39.5-13t-42.5-6l205-324 65 47-188 296Z" />
                </svg>
              </div>
              <div className="flex flex-col mr-2">
                <h3 className="text-lg font-bold mb-2"> تحقيق نمو الأرباح</h3>
                <p className="text-sm">الأتمتة الشاملة تساعدك في تعزيز الربحية عبر تقليل التكاليف.</p>
              </div>
            </div>
          </motion.div>

          {/* Card 2 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }} // Increased animation duration for Card 2
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start">
              <div className="flex items-center justify-center bg-emerald-600 rounded-lg p-1 m-1 w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFD700">
                  <path d="M600-120h240v-33q-25-23-56-35t-64-12q-33 0-64 12t-56 35v33Zm120-120q25 0 42.5-17.5T780-300q0-25-17.5-42.5T720-360q-25 0-42.5 17.5T660-300q0 25 17.5 42.5T720-240ZM480-480Zm2-140q-58 0-99 41t-41 99q0 48 27 84t71 50q0-23 .5-44t8.5-38q-14-8-20.5-22t-6.5-30q0-25 17.5-42.5T482-540q15 0 28.5 7.5T533-512q11-5 23-7t24-2h36q-13-43-49.5-71T482-620ZM370-80l-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-85 65H696q-1-5-2-10.5t-3-10.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q24 25 54 42t65 22v184h-70Zm210 40q-25 0-42.5-17.5T520-100v-280q0-25 17.5-42.5T580-440h280q25 0 42.5 17.5T920-380v280q0 25-17.5 42.5T860-40H580Z" />
                </svg>
              </div>
              <div className="flex flex-col mr-2">
                <h3 className="text-lg font-bold mb-2">تقليل الأخطاء المحاسبية </h3>
                <p className="text-sm">الأتمتة الذكية تقلل الأخطاء وتضمن دقة البيانات المحاسبية.</p>
              </div>
            </div>
          </motion.div>

          {/* Card 3 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }} // Increased animation duration for Card 3
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start">
              <div className="flex items-center justify-center bg-emerald-600 rounded-lg p-1 m-1 w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFD700">
                  <path d="M206-206q-41-48-63.5-107.5T120-440q0-150 105-255t255-105h8l-64-64 56-56 160 160-160 160-57-57 63-63h-6q-116 0-198 82t-82 198q0 51 16.5 96t46.5 81l-57 57Zm234-14q0-23-15.5-45.5t-34.5-47q-19-24.5-34.5-51T340-420q0-58 41-99t99-41q58 0 99 41t41 99q0 30-15.5 56.5t-34.5 51q-19 24.5-34.5 47T520-220h-80Zm0 100v-60h80v60h-80Zm314-86-57-57q30-36 46.5-81t16.5-96q0-66-27.5-122.5T657-657l57-57q58 50 92 120.5T840-440q0 67-22.5 126.5T754-206Z" />
                </svg>
              </div>
              <div className="flex flex-col mr-2">
                <h3 className="text-lg font-bold mb-2">زيادة كفاءة العمليات</h3>
                <p className="text-sm">نظام محاسبي ذكي يحسّن الأداء، ويوفر الوقت بتقليل المهام اليدوية.</p>
              </div>
            </div>
          </motion.div>
          {/* Card 4 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0 }} // Increased animation duration for Card 1
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start">
              <div className="flex items-center justify-center bg-emerald-600 rounded-lg p-1 m-1 w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#FFD700">
                  <path d="M260-361v-40H160v-80h200v-80H200q-17 0-28.5-11.5T160-601v-160q0-17 11.5-28.5T200-801h60v-40h80v40h100v80H240v80h160q17 0 28.5 11.5T440-601v160q0 17-11.5 28.5T400-401h-60v40h-80Zm298 240L388-291l56-56 114 114 226-226 56 56-282 282Z" />
                </svg>
              </div>
              <div className="flex flex-col mr-2">
                <h3 className="text-lg font-bold mb-2"> توفير تكاليف المحاسبة</h3>
                <p className="text-sm">تخلص من تكاليف توظيف فريق محاسبي داخلي باعتماد الأتمتة الذكية.</p>
              </div>
            </div>
          </motion.div>
          {/* Card 5 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0 }} // Increased animation duration for Card 1
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start">
              <div className="flex items-center justify-center bg-emerald-600 rounded-lg p-1 m-1 w-8 h-8">
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" className="fill-yellow-500">
                  <path d="M380-740q-33 0-56.5-23.5T300-820q0-33 23.5-56.5T380-900q33 0 56.5 23.5T460-820q0 33-23.5 56.5T380-740ZM120-40l110-564-70 30v134H80v-188l210-86q27-11 55-2.5t43 34.5l38 64q27 44 72.5 71T600-520v80q-66 0-122.5-28T382-544l-24 120 82 82v302h-80v-240l-86-80-70 320h-84Zm550 0v-560H520v-280h360v280H730v560h-60Zm51-601 99-99-99-99-43 43 26 26H580v60h124l-26 26 43 43Z" />
                </svg>
              </div>
              <div className="flex flex-col mr-2">
                <h3 className="text-lg font-bold mb-2"> لا حاجة لمتابعة المحاسبين</h3>
                <p className="text-sm">مع المحاسب الذكي، الإدخالات دقيقة تلقائيًا، مما يغنيك عن متابعة المحاسبين لضمان صحة البيانات.</p>
              </div>
            </div>
          </motion.div>
          {/* Card 6 */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0 }} // Increased animation duration for Card 4
            className="bg-white rounded-lg border border-light-gray flex flex-col pt-6 pb-2"
          >
            <div className="flex items-start justify-center p-4">
              <img src="kite.png" alt="png" className="w-1/4" />
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
