import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
const ClientLogos = () => {
  // First row logos array
  const logosRow1 = [
    { id: 1, src: 'client1.jpg', alt: 'Tweety Cafe' },
    { id: 2, src: 'client2.jpeg', alt: 'Sama Cuisine' },
    { id: 3, src: 'client4.jpg', alt: 'Raheeb' },
    { id: 11, src: 'client7.jpeg', alt: 'Logo One' },
    { id: 5, src: 'client5.jpeg', alt: 'YaoYao Bubble Tea' },
    { id: 6, src: 'client6.png', alt: 'Haza Ryoq' },

  ];

  // Second row logos array (different from the first)
  // const logosRow2 = [
  //   { id: 11, src: 'client7.jpg', alt: 'Logo One' },
  //   { id: 12, src: 'client8.webp', alt: 'Logo Two' },
  //   { id: 14, src: 'client10.webp', alt: 'Logo Four' },
  //   { id: 13, src: 'client9.webp', alt: 'Logo Three' },
  //   { id: 15, src: 'client11.webp', alt: 'Logo Five' },

  // ];

  const [currentIndex1, setCurrentIndex1] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [logosToShow, setLogosToShow] = useState(2); // Default number of logos to display

  useEffect(() => {
    const updateLogosToShow = () => {
      if (window.innerWidth >= 1024) {
        setLogosToShow(5); // Desktop
      } else if (window.innerWidth >= 768) {
        setLogosToShow(4); // Tablet
      } else {
        setLogosToShow(2); // Mobile
      }
    };

    updateLogosToShow(); // Set initial value
    window.addEventListener('resize', updateLogosToShow); // Update on resize

    const interval1 = setInterval(() => {
      setCurrentIndex1((prevIndex) => (prevIndex + 1) % logosRow1.length);
    }, 1500); // Change every 1 second for the first row

    // const interval2 = setInterval(() => {
    //   setCurrentIndex2((prevIndex) => (prevIndex - 1 + logosRow2.length) % logosRow2.length); // Change every 2 seconds for the second row (loop right)
    // }, 2000);

    return () => {
      clearInterval(interval1); // Cleanup on component unmount for the first row
      // clearInterval(interval2); // Cleanup on component unmount for the second row
      window.removeEventListener('resize', updateLogosToShow); // Cleanup resize event listener
    };
  }, []);

  // Function to get the logos to display for the first row
  const getDisplayedLogosRow1 = () => {
    const displayedLogos = [];
    for (let i = 0; i < logosToShow; i++) {
      displayedLogos.push(logosRow1[(currentIndex1 + i) % logosRow1.length]);
    }
    return displayedLogos;
  };

  // Function to get the logos to display for the second row
  // const getDisplayedLogosRow2 = () => {
  //   const displayedLogos = [];
  //   for (let i = 0; i < logosToShow; i++) {
  //     // For second row, loop in reverse
  //     displayedLogos.push(logosRow2[(currentIndex2 + i) % logosRow2.length]);
  //   }
  //   return displayedLogos;
  // };

  return (
    <div className="w-full py-32 bg-white">
         {/* Helmet for SEO */}
         <Helmet>
        <title>العملاء</title>
        <meta name="description" content="Explore our esteemed clients who trust us with their financial technology needs." />
        <meta name="keywords" content="Clients, Financial Technology, سند الذكي" />
      </Helmet>

      {/* Heading */}
      <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
        <span className="font-bold">عملاء</span>{' '}
        <span className="text-emerald-600 font-bold">محاسب سند الذكي</span>
      </h2>

      {/* First Row: Moving Logos to the left */}
      <div className="flex justify-center gap-8 mb-8 flex-wrap">
        {getDisplayedLogosRow1().map((logo) => (
          <div
            key={logo.id}
            className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full border border-emerald-300 flex items-center justify-center"
          >
            <img src={logo.src} alt={logo.alt} className="object-cover w-full h-full rounded-full" />
          </div>
        ))}
      </div>

      {/* Second Row: Moving Logos to the right */}
      {/* <div className="flex justify-center gap-8 flex-wrap">
        {getDisplayedLogosRow2().map((logo) => (
          <div
            key={logo.id}
            className="w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full border border-emerald-300 flex items-center justify-center"
          >
            <img src={logo.src} alt={logo.alt} className="object-cover w-full h-full rounded-full" />
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ClientLogos;
