import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Helmet } from 'react-helmet-async';

const HowItWorks = () => {
  // Array of sections
  const sections = [
    { title: 'إختر الباقة المناسبة', text: 'لدينا باقات متنوعة تلبي كل الاحتياجات.', img: 'choose.png' },
    { title: 'قم بتعبئة بياناتك', text: 'بعد إختيارك للباقه وتعبئه بياناتك سوف يتم التواصل معك.', img: 'chat.png' },
    { title: 'تعرف على فريقك', text: 'فى حال احتياجك لمحاسب سوف يتم ربطك فورا بأفضل الكفاءات فى المحاسبه.', img: 'team.png' },
    { title: 'بدء العمل', text: 'عند اختيارك لخدمة المحاسب الذكي، سيتم تزويدك بمنصة متكاملة لإرسال الفواتير ومعالجتها تلقائيًا، مما يوفر لك الوقت والجهد.', img: 'work.png' },
  ];

  return (
    <div className="pt-16 overflow-x-hidden"> {/* Prevent horizontal scroll */}
      {/* SEO Metadata */}
      <Helmet>
        <title>كيف نعمل</title>
        <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
      </Helmet>
      {/* Title */}
      <h2 className="text-4xl md:text-5xl lg:text-6xl my-14 text-gray-900 font-bold text-center">
        كيف تبدأ معنا
      </h2>

      {/* Content sections */}
      <div className="relative overflow-x-hidden"> {/* Ensure content doesn't overflow */}
        {/* Wave Shape */}
        <div className="absolute top-0 left-0 w-full overflow-hidden leading-none bg-white">
          <svg className="relative block w-full h-48" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="white"
              fillOpacity="1"
              d="M0,160L60,160C120,160,240,160,360,170.7C480,181,600,203,720,197.3C840,192,960,160,1080,149.3C1200,139,1320,149,1380,154.7L1440,160L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            />
          </svg>
        </div>

        {/* Section: Repeated Content with alternating image positions */}
        {sections.map((item, index) => (
          <AnimatedSection
            key={index}
            index={index}
            title={item.title}
            text={item.text}
            img={item.img}
          />
        ))}
      </div>
    </div>
  );
};

// Component for each animated section
const AnimatedSection = ({ index, title, text, img }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: 0.5, // Trigger animation when 50% of the element is in view
    triggerOnce: false, // Keep triggering on scroll in and out
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, x: 0 });
    } else {
      controls.start({ opacity: 0, x: index % 2 === 0 ? -50 : 50 }); // Reduced x-shift to avoid overflow
    }
  }, [controls, inView, index]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }} // Reduced x-shift
      transition={{ duration: 0.6, ease: 'easeOut' }}
      className={`flex flex-col lg:flex-row items-center ${index >= 2 ? 'py-6' : 'py-10'} px-4 lg:px-20 bg-white relative z-10 ${index % 2 === 1 ? 'lg:flex-row-reverse' : ''} p-6`} // Added padding inside each section
    >
      {/* Image */}
      <div className={`w-full lg:w-1/4 lg:-mt-12 ${index === 0 ? 'lg:ml-40' : index === 1 ? 'lg:mr-60' : index === 2 ? 'lg:ml-40' : index === 3 ? 'lg:mr-60' : ''}`}>
        <div className="flex justify-center w-full">
          <img src={img} alt="Illustration" className="w-2/3 md:w-1/2 lg:w-4/5" />
        </div>
      </div>

      {/* Text and Button */}
      <div dir="rtl" className={`w-full lg:w-2/3 text-right lg:px-6 mt-6 lg:mt-0 ${index === 1 ? 'lg:mr-24' : index === 2 ? 'lg:ml-12' : index === 3 ? 'lg:mr-24' : index === 4 ? 'lg:ml-12' : ''}`}>
        <h3 className="text-2xl md:text-3xl lg:text-4xl max-w-md min-w-xs mb-4">{title}</h3>
        <p className="text-base md:text-lg lg:text-xl max-w-md min-w-xs text-gray-700 mb-6">{text}</p>
        <button className="bg-black text-white max-w-md min-w-xs py-2 px-6 rounded-full text-lg hover:bg-gray-800 transition duration-300"
          onClick={() => window.open('https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI', '_blank', 'noopener noreferrer')}
        >
          سجل حساب من هنا
        </button>
      </div>
    </motion.div>
  );
};

export default HowItWorks;
