import { useAnimation } from "framer-motion";
import { motion, useInView } from 'framer-motion';
import { useEffect, useRef } from "react";
import { Helmet } from "react-helmet-async";

const JobHeader = () => {
  const controls = useAnimation(); // Controls animation state
  const ref = useRef(null); // Ref for the main div
  const refButton = useRef(null); // Ref for the button

  const inView = useInView(ref, { threshold: 0.5 }); // Observe the main div
  const inViewButton = useInView(refButton, { threshold: 0.5 }); // Observe the button

  // Start animation when the component is in view and reverse when out
  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 100 });
    }
  }, [controls, inView]);

  return (
    <>
      {/* SEO Metadata */}
      <Helmet>
        <title>Wave Divider - Your Site Name</title>
        <meta name="description" content="Discover how to send invoices with ease using our smart accounting software." />
      </Helmet>
      <motion.div
        ref={ref} // Hook the ref to the div to observe when it's in view
        animate={controls} // Bind animation control to this div
        initial={{ opacity: 0, y: 100 }} // Initial state
        transition={{ duration: 0.6, ease: "easeOut" }} // Smooth transition
      >
        {/* New Section */}
        <div className="text-center pt-32 mb-12">
          <h2 dir="rtl" className="text-4xl md:text-5xl font-bold mb-6 p-2">
            فريقنا هو الأكثر موهبة في مجال التقنيه الماليه
          </h2>
          <motion.a
            className="bg-gray-800 text-white py-3 mt-10 px-8 rounded-full hover:bg-gray-700 transition mt-10"
            ref={refButton} // Ref for observing button
            initial={{ opacity: 0, x: -100, scale: 0 }} // Start hidden and scaled down
            animate={{
              opacity: inViewButton ? 1 : 0,
              x: inViewButton ? 0 : -100,
              scale: inViewButton ? 1 : 0,
            }} // Animate when in view
            transition={{ duration: 0.5 }} // Animation duration
            href="https://forms.clickup.com/9005174595/f/8cc03u3-5515/3X5KA88Y5DVUVQ9ZRI" target='_blank'
          >
            اشترك معنا
          </motion.a>
        </div>
      </motion.div>
    </>
  );
};

export default JobHeader;
