import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import App from './App';
import './index.css';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  
//     <App />

// );
const container = document.getElementById('root');
hydrateRoot(container,<App/>);

